<template>
  <div class="conbody">
    <navbar type="sight" />
    <div class="app-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import navbar from "../components/NavBar.vue";
export default {
  components: {
    navbar
  }
};
</script>

<style>
.conbody {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}
.app-container {
  padding: 20px 40px;
}
</style>