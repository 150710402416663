<template>
  <div class="navnbar">
    <span class="logo">
      <img src="@/assets/logo.png" alt height="20px" />
    </span>
    <router-link to="/sightcompany/order">
      <span>订单</span>
    </router-link>
    <router-link to="/sightcompany/Tickets">
      <span>门票</span>
    </router-link>
    <router-link to="/sightcompany/SightAdmin">
      <span>景区管理</span>
    </router-link>
    <router-link to="/sightcompany/CommentSight">
      <span>评论与实时信息</span>
    </router-link>
    <router-link to="/sightcompany/WalletSight">
      <span>钱包</span>
    </router-link>
    <router-link to="/sightcompany/companyinfosight" v-if="biz.membType==0">
      <span>公司信息</span>
    </router-link>
    <router-link to="/sightcompany/messagesight">
      <span>
        消息
        <i v-if="biz.msgCount>0">( {{ biz.msgCount }} )</i>
      </span>
    </router-link>
    <router-link to="/sightcompany/servicesight">
      <span>客服</span>
    </router-link>

    <div class="nav">
      <span style="font-weight:bold" v-if="type=='store'">{{ biz.nameStore }}</span>
      <span style="font-weight:bold" v-else>{{ biz.noteNameBiz }}</span>
      <span>{{ biz.nickName }}</span>
      <span @click="quit" style="margin:0;cursor:pointer">退出</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      message: "3" //未读消息统计
    };
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    quit() {
      this.$router.push({ name: "login" });
      localStorage.setItem("Authorization", "");
    }
  }
};
</script>

<style scoped>
.navnbar {
  padding: 10px 40px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
}
.nav {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nav span {
  margin-right: 40px;
  color: #fff;
}

.router-link-exact-active span {
  text-decoration: none;
  color: darkorange;
}
.router-link-active span {
  text-decoration: none;
  color: darkorange;
}
.logo {
  padding: 6px;
  border-radius: 100%;
  background: #fff;
}
i {
  color: darkorange;
}
</style>